// **
// Checkbox
// **
.cookies-checkboxes {
	.wysiwyg {
		margin-bottom: 30px;
	}
	.cookies-checkbox {
		margin-top: 10px;
		margin-left: 40px;
		position: relative;
		&.checkable {
			cursor: pointer;
		}
		&.active .checkbox-element:before {
			display: block;
		}
	}
	.content {
		p {margin: 0;}
		p + p {
			margin-top: 6px;
		}
		.bold {
			color: $theme-color;
			font-weight: bold;
		}
	}
	.checkbox-element {
		position: absolute;
		left: -38px;
		top: 2px;
		width: 20px;
		height: 20px;
		border: 1px solid #dbdcdc;
		display: block;
		box-shadow: 0 0 0 4px #eaebeb;
		&:before {
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
			margin-left: 1px;
			margin-top: -1px;
			content: "✔";
			display: none;
			color: $theme-color;
		}
	}
}

// **
// Cookies bar
// **
.cookies-popup {
	position: fixed;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	display: none;
	z-index: 10000;
	.cookies-overlay {
		position: fixed;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		background-color: rgba(#000, 0.8);
		content: "";
		display: block;
	}
}
.cookies-bar {
	position: fixed;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	padding: 80px 54px 110px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	background: #fff url("/images/cookies-logo.png") 0 50% no-repeat;
	overflow: hidden;
	width: 630px;
	max-width: 100%;

	.content-container {
		position: relative;
		z-index: 2;
	}

	// Sub title (div)
	.top-title {
		margin-left: 58px;
		font-size: 18px;
		line-height: 1.4em;
		text-transform: uppercase;
		color: #fed255;
		font-weight: 600;
		letter-spacing: 0.25em;
	}

	// Title
	.title {
		font-size: 60px;
		line-height: 1.1em;
		font-weight: bold;
		margin-top: 20px;
		margin-bottom: 30px;
		position: relative;
		text-transform: uppercase;
	}

	// Content
	.content {
		margin-left: 58px;
		position: relative;
		color: $theme-color;
		p {
			margin: 12px 0 0;
			font-size: 15px;
			line-height: 25px;
			&.bold {
				font-size: 17px;
				font-weight: 600;
			}
		}
	}

	// Btns
	.btns-container {
		display: flex;
		align-items: center;
		margin-top: 30px;
		margin-left: -12px;
		position: relative;
		.link-more {
			margin-left: 20px;
			cursor: pointer;
			color: $theme-color;
			text-decoration: none;
			font-size: 16px;
			line-height: 28px;
			letter-spacing: 0.1em;
			&:hover {
				text-decoration: underline;
			}
		}
	}
	.btn {
		position: relative;
		background-color: #fff;
		padding: 12px 50px;
		font-size: 16px;
		letter-spacing: 0.15em;
		text-transform: uppercase;
		transition: all .25s ease;
		min-height: 94px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		border-radius: 6px;
		border: 2px solid #c1c3c3;
		color: $theme-color;
		margin: 0;
		text-align: center;
		vertical-align: middle;
		cursor: pointer;
		background-image: none;
		white-space: nowrap;
		touch-action: manipulation;
		user-select: none;
		&:hover {
			border-color: $theme-color;
			background: $theme-color;
			color: #fff;
		}
	}

	// Responsive
	@media (max-width: 1400px) {
		padding-top: 70px;
		padding-bottom: 70px;
		.title {
			font-size: 50px;
			line-height: 1.1em;
		}
		.content {
			p {
				font-size: 14px;
				line-height: 1.4em;
				&.bold {
					font-size: 16px;
				}
			}
		}
	}
	@media (max-width: 1170px) {
		padding: 60px 40px 60px 50px;
		background-size: 40px auto;
		.top-title, .content {
			margin-left: 0;
		}
		.btns-container {
			margin-left: 0;
		}
		.btn {
			min-height: 70px;
			padding-left: 30px;
			padding-right: 30px;
		}
	}
	@media (max-width: 900px) {
		padding: 30px 30px 40px 40px;
		background-size: 30px auto;
		.title {
			font-size: 40px;
			line-height: 1.1em;
			margin-bottom: 8px;
		}
		.content {
			p {
				margin-top: 4px;
			}
		}
		.btn {
			min-height: 50px;
			padding-left: 22px;
			padding-right: 22px;
			font-size: 14px;
			letter-spacing: 0.05em;
		}
		.btns-container {
			.link-more {
				font-size: 14px;
				letter-spacing: 0.05em;
			}
		}
	}

	@media (max-width: 720px) {
		max-width: 94%;
		padding: 28px 24px;
		background-image: none;
		[data-responsive="0"] {
			display: none;
		}
		.title {
			font-size: 20px;
			line-height: 1.2em;
			margin-top: 0;
		}
		.content {
			margin-top: 0;
			p {
				margin-top: 0;
				&.bold {
					font-size: 15px;
				}
			}
		}
		.btns-container {
			margin-top: 10px;
			.link-more {
				margin-left: 12px;
				letter-spacing: 0;
			}
		}
	}
}